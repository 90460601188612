import(/* webpackMode: "eager" */ "/root/build2/ender/node_modules/.pnpm/next@14.2.15_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.49.1_babel-plugin_r2wnblcmkyn37khvh27jzit55a/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/root/build2/ender/node_modules/.pnpm/next@14.2.15_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.49.1_babel-plugin_r2wnblcmkyn37khvh27jzit55a/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/root/build2/ender/node_modules/.pnpm/next@14.2.15_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.49.1_babel-plugin_r2wnblcmkyn37khvh27jzit55a/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/root/build2/ender/node_modules/.pnpm/next@14.2.15_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.49.1_babel-plugin_r2wnblcmkyn37khvh27jzit55a/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/root/build2/ender/node_modules/.pnpm/next@14.2.15_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.49.1_babel-plugin_r2wnblcmkyn37khvh27jzit55a/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/root/build2/ender/node_modules/.pnpm/next@14.2.15_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.49.1_babel-plugin_r2wnblcmkyn37khvh27jzit55a/node_modules/next/dist/client/components/render-from-template-context.js");
